<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Tra cứu thông tin giấy cấp phép lao động'"
    />
    <!-- End Hero -->

    <main
      id="main"
    >
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div
          id="license"
          class="container"
        >
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
              style="color: #000000"
            >
              Tra cứu
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
            >
              Tra cứu thông tin giấy cấp phép lao động
            </div>
          </div>
          <div
            class="mt-2 search-input w-100 d-flex search-home"
          >
            <search
              icon="Trash2Icon"
              class="w-100 searh-h2"
              placeholder="Nhập số giấy phép"
              @change="search"
            />
            <feather-icon
              icon="SearchIcon"
              size="16"
              class="text-body icon-search"
            />

          </div>

          <b-card
            v-if="dataLicense !== null"
            header-bg-variant="primary"
            border-variant="light"
            header="Thông tin giấy phép"
            header-text-variant="white"
            class="text-center mt-4 animate__animated animate__fadeInUp"
          >
            <b-card-body>
              <div class="card-body-home-page">
                <div>
                  <b-row class="m-1">
                    <feather-icon
                      icon="ListIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span> Số giấy phép</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Họ tên</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="FileIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Loại giấy</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Doanh nghiệp</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Ngày bắt đầu</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Ngày kết thúc</span>
                  </b-row>
                  <b-row class="m-1">
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="16"
                      class="mr-1 icon-card"
                    />
                    <span>Trạng thái</span>
                  </b-row>

                </div>
                <div>
                  <b-row class="m-1 item-list-val">
                    {{ dataLicense.numberLicense }}
                  </b-row>
                  <b-row class="m-1 item-list-val">
                    {{ dataLicense.name }}

                  </b-row>
                  <b-row class="m-1 item-list-val">
                    <b-badge
                      pill
                      :variant="statusVariant(dataLicense.workerLicenseType)"
                      class="border-status"
                    >
                      {{ dataLicense.workerLicenseTypeString }}
                    </b-badge>
                  </b-row>
                  <b-row class="m-1 item-list-val">
                    {{ dataLicense.bussiness }}
                  </b-row>
                  <b-row class="m-1 item-list-val">
                    {{ dataLicense.dateStart |formatDateToDDMM }}
                  </b-row>
                  <b-row class="m-1 item-list-val">
                    {{ dataLicense.dateEnd|formatDateToDDMM }}

                  </b-row>
                  <b-row class="m-1 item-list-val">
                    <b-badge
                      pill
                      :variant="colorStatus(dataLicense.status)"
                      class="border-status"
                    >
                      {{ dataLicense.statusString }}
                    </b-badge>
                  </b-row>
                </div>
              </div>
            </b-card-body>
          </b-card>
          <div
            v-show="dataLicense === null"
            class="mt-4"
            style="background: #ffff;"
          >
            <div class="empty-table d-block">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <b-img
                  class="img-empty-table size-empty"
                  fluid
                  :src="emptyImg"
                  alt="empty table"
                />
              </div>
              <h5 class="w-100 d-flex align-items-center justify-content-center mt-2 pb-4">
                Nhập số giấy phép để có dữ liệu
              </h5>
            </div>
          </div>

        </div>
      </section>
      <!-- End Team Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />

    </footer>
    <!-- End Footer -->
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BImg,
  BCard,
  BBadge,
  BMedia,
  BDropdown,
  BCol,
  BRow,
  BDropdownItem,
  BButton,
  VBTooltip,
  BFormInput,
  BCardBody,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import Carousel from './components/Carousel.vue'
import SiteFooter from './components/SiteFooter.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'
import SiteHeader from './components/SiteHeader.vue'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    BCol,
    BRow,
    SiteHeader,
    Carousel,
    VBTooltip,
    SiteFooter,
    BFormInput,
    BCard,
    BCardBody,
    BImg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      formatFullName,
      // eslint-disable-next-line global-require
      emptyImg: require('@/assets/images/svg/No-data.gif'),
      currentPage: 0,
      urlQuery: {
        numberLicense: '',
      },
      totalRecord: 0,
      dataLicense: {},
    }
  },
  computed: {
    colorStatus() {
      const statusColor = {
        None: 'info',
        NonLiscening: 'secondary',
        PendingConfirm: 'warning',
        Confirm: 'success',
        RejectConfirm: 'danger',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        PendingLicensing: 'warning',
        Extend: 'success',
        Renew: 'primary',
        RejectExtend: 'danger',
        RejectRenew: 'danger',
      }
      return status => statusColor[status]
    },

    statusVariant() {
      // eslint-disable-next-line default-case
      const typeColor = {
        License: 'light-primary',
        Confirm: 'light-success',
      }
      return color => typeColor[color]
    },
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      }
      return document.querySelector(el)
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = el => {
      const header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }

      const elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on('click', '.mobile-nav-toggle', function (e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.navbar .dropdown > a',
      // eslint-disable-next-line func-names
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true,
    )

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.scrollto',
      // eslint-disable-next-line func-names
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          const navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            const navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true,
    )

    /**
     * Skills animation
     */
    const skilsContent = select('.skills-content')
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: '80%',
        handler(direction) {
          const progress = select('.progress .progress-bar', true)
          progress.forEach(el => {
            el.style.width = `${el.getAttribute('aria-valuenow')}%`
          })
        },
      })
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper('.testimonials-carousel', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      const portfolioContainer = select('.portfolio-container')
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-wrap',
          layoutMode: 'fitRows',
        })

        const portfolioFilters = select('#portfolio-flters li', true)

        // eslint-disable-next-line func-names
        on(
          'click',
          '#portfolio-flters li',
          // eslint-disable-next-line func-names
          function (e) {
            e.preventDefault()
            portfolioFilters.forEach(el => {
              el.classList.remove('filter-active')
            })
            this.classList.add('filter-active')

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            })
            portfolioIsotope.on('arrangeComplete', () => {
              // eslint-disable-next-line no-undef
              AOS.refresh()
            })
          },
          true,
        )
      }
    })

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: '.portfolio-lightbox',
    })

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false,
      })
    })
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      await axiosApiInstance.get(ConstantsApi.WORKOER_LICENSE, {
        params: urlQuery,
      }).then(response => {
        if (response.status === 200) {
          this.dataLicense = response?.data
        } else {
          this.dataLicense = null
        }
      }).catch(e => {
        this.dataLicense = null
      })
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.numberLicense = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.numberLicense = ''
        this.fetchData(this.urlQuery)
      }
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },
  },
}
</script>

<style lang="scss">
#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

#license {
  .vgt-table {
    th {
      background-color: #0282CD;
      color: #fff !important;
    }
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }

  .search-home{
    position: relative;
    .form-control{
      border-radius: 0;
      border: 1px solid #ffffff;
    }
  }
  .icon-search{
    position: absolute;
    right: 0;
    margin: 22px;
  }
  .card-body-home-page{
    display: flex;
    justify-content: space-between;
  }

  .icon-card{
    margin-top: 3px;
  }
  .item-list-val{
    display: flex;
    justify-content: end;
  }
  .searh-h2 input{
    height: 58px;
    padding-left: 20px;
  }
  .size-empty{
    height: 225px;
    width: 225px;
  }
}

@media (max-width: 600px){
  #license {
      padding: 10px !important;
    .card {
      .card-body{
        padding: 10px;
      }
      .item-list-val{
        display: flex;
        justify-content: end;
         width: 250px;
         white-space: nowrap;
         overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
